<template>
  <div
    v-if="asButton"
    :title="$t('copy', { item: topic })"
    @click.stop.prevent="copyToClipboard()"
  >
    <i class="fa fa-copy"></i>
  </div>
  <span v-else :class="$attrs.active ? 'topic text-warning' : 'topic'">
    {{ topic }}
    <i class="fa fa-copy" @click.stop.prevent="copyToClipboard()"></i>
  </span>
</template>

<script>
import { mqttTopic } from "@/services/equipment.js";

export default {
  name: "MQTTTopicSpan",
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  computed: {
    topic() {
      return mqttTopic(this.entry);
    },
    inClipboard() {
      return this.$parent.$data.clipboardText == this.topic;
    }
  },
  methods: {
    copyToClipboard() {
      this.$utils.toClipboard(this.topic);
      this.$emit("active");
      let infoHtml = this.$t("item_copied");
      let options = {
        type: "success",
        icon: "copy",
        iconPack: "fontawesome",
        singleton: true,
        duration: 10000, // 10s
        position: "bottom-right",
        action: {
          icon: "fa-close",
          onClick: (e, me) => {
            me.goAway(0);
          }
        }
      };
      this.$toasted.clear();
      this.$toasted.show(infoHtml, options);
    }
  },
  beforeCreate() {
    this.asButton =
      (this.$options?._parentVnode?.data?.staticClass || "").indexOf("btn") !=
      -1;
  }
};
</script>

<style scoped>
.topic > i {
  color: transparent;
  margin-left: 5px;
}

.topic:hover > i {
  color: inherit;
}
</style>
